import React from 'react';
import { Container, Row } from 'react-bootstrap';
import LandingPageLayout from './LandingPageLayout';
import _ from 'lodash';

import './ErrorBoundary.css';



type OwnProps = {
    fullPage?: boolean,
}

type AllProps = OwnProps;

type OwnState = {
    crashed: boolean,
    error?: any
}


export class ErrorBoundary extends React.Component<AllProps, OwnState> {
    displayName = ErrorBoundary.name

    constructor(props: AllProps) {
        super(props);

        this.state = {
            crashed: false,
            error: undefined
        };
    }

    componentDidCatch(error: any, info: any) {
        console.log('Catched an error in error boundary:');
        console.log(error);
        this.setState({ crashed: true, error: error });
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { crashed: true };
    }

    errorToString(): string {
        const { error } = this.state;
        if (error) {
            if (_.isError(error)) {
                return `Name: ${error.name}\nMessage: ${error.message}\n${error.stack}`
            } else {
                try {
                    return error.toString();
                }
                catch (err) {
                    return '';
                }
            }
        }
        return '';
    }

    getErrorMessage(): string {
        const { error } = this.state;
        if (error) {
            if (_.isError(error)) {
                return error.message;
            }
        }
        return '';
    }

    render() {
        return this.state.crashed ? (this.props.fullPage === true ?
            (
                <div>
                    <div>An error has occurred.</div >
                    {
                        this.state.error && !!this.getErrorMessage() && (
                            <>
                                <div>{this.getErrorMessage()}</div>
                            </>
                        )
                    }
                </div>
            ) :
            (
                <Container fluid className="home-page">
                    <Row>
                        <LandingPageLayout>
                            <div className="crash-page">
                                <div>Application has crashed.</div >
                                <div>When reporting this error, please include a screenshot of your browser console output.</div>
                                <div>To access the console, press <b>F12</b> to open your browser's developer tools and switch to the <b>Console</b> tab.</div>
                                <div>On Windows you can use the built-in applications <b>Snipping Tool</b> or <b>Snip &amp; Sketch</b> to take screenshots.</div>
                                {
                                    this.state.error && (
                                        <>
                                            <div>The following error message was received:</div>
                                            <div className="error-message-container">{this.errorToString()}</div>
                                        </>
                                    )
                                }
                            </div >
                        </LandingPageLayout>
                    </Row>
                </Container>))
            : this.props.children;
    }
}
