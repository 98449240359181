import React from 'react';
import { RouteComponentProps, Route, withRouter, Redirect, Switch } from 'react-router-dom';

import AnnotationPage from './components/annotation-page/AnnotationPage';
import LocalPage from './components/local-file-page/LocalPage';
import LiveReviewPage from './components/live-review-page/LiveReviewPage';
import Layout from './components/Layout';
import AuthenticatedPage from './components/AuthenticatedPage';
import ColorExtractPage from './components/color-extract-page/ColorExtractPage';
import ModelMappingPage from './components/model-mapping-page/ModelMappingPage';
import LoggedOutPage from './components/logged-out-page/LoggedOutPage';
import { ErrorBoundary } from './components/ErrorBoundary';

import './App.css'
import './Animations.css'
import './branding.css';

interface IProps extends RouteComponentProps<any> {
}


class App extends React.Component<IProps> {
  public render() {
    return (

      <ErrorBoundary fullPage>

        <Switch>
          <Route exact path='/logged-out' component={LoggedOutPage} />

          <AuthenticatedPage>
            <Layout>
              <Switch>
                <Route exact path='/' component={LocalPage} />
                <Route path='/local' component={LocalPage} />
                <Route path='/annotation' component={AnnotationPage} />
                <Route path='/livereview' component={LiveReviewPage} />

                <Route path="/tools">
                  <Route path='/tools/color' component={ColorExtractPage} />
                  <Route path='/tools/models' component={ModelMappingPage} />
                </Route>

                <Route exact path="/color">
                  <Redirect exact from="/color" to="/tools/color" />
                </Route>
                <Route exact path="/models">
                  <Redirect exact from="/models" to="/tools/models" />
                </Route>
              </Switch>
            </Layout>
          </AuthenticatedPage>

        </Switch>

      </ErrorBoundary>

    );
  }
}

export default withRouter<IProps, any>(App);
